import { Alert, AlertTitle, Backdrop, Button, CircularProgress, Container, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { parsev2URL } from "../utils";
import AnyListTable from "../components/anyList";
import assert from "assert";

export default function Settingsv2Page() {
    const [isLoading, setIsLoading] = useState(false);
    const [authStatus, setAuthStatus] = useState("未知");
    const [apiStatus, setApiStatus] = useState("未知");
    const [apiDelay, setApiDelay] = useState(0);

    // const [historyDicts, setHistoryDicts] = useState([{},])
    const [historyList, setHistoryList] = useState([]);

    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => setIsSnackbarOpen(errMsg != ""), [errMsg])

    const fetchStatus = async () => {
        setIsLoading(true);
        const apiStartTime = Date.now();
        var duration = 0;
        const apiStatus = await fetch(parsev2URL("/status"))
            .then(res => res.json())
            .catch(() => ({ status: "error" }))
            .finally(() => { setApiDelay(Date.now() - apiStartTime) });
        const authStatus = await fetch("/auth/v1/status")
            .then(res => res.json())
            .catch(() => ({ status: "error" }));

        setAuthStatus(authStatus.status == "ok" ? "好" : "坏");
        setApiStatus(apiStatus.status == "ok" ? "好" : "坏");
        setIsLoading(false);
    }

    const fetchHistory = async () => {
        setIsLoading(true);
        await fetch(parsev2URL("/relogin"), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "uid": localStorage.getItem("uid"),
                "username": localStorage.getItem("id"),
            }),
        } // relogin
        ).then(res => {
            if (res.status != 200) {
                throw new Error(res.statusText);
            }
        }).catch(err => {
            setErrMsg(
                `出现错误，请尝试重新登录 RunTech -  ${err}`
            )
            console.log(err);
        });
        const historyDicts = await fetch(
            parsev2URL("/history"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "uid": localStorage.getItem("uid"),
                    "username": localStorage.getItem("id"),
                }),
            }
        )
            .then(res => {
                if (res.status != 200) {
                    throw new Error(res.statusText);
                }
                return res.json();
            })
            .then(data => {
                (data as Array<any>).sort((a, b) => a.HDZT - b.HDZT);
                setHistoryList(data.map(
                    (dict: any) => [
                        (dict.LCQD && dict.RCQD && dict.HDLX_DISPLAY == "形势与政策课程讲座") ? "\u2714\uFE0F" : "\u274C\uFE0F",
                        (dict.RCQD) ? "🔵" : "🔴",
                        (dict.LCQD) ? "🔵" : "🔴",
                        dict.HDZT_DISPLAY,
                        dict.HDMC
                    ]
                ))
            })
            .catch(err => {
                setErrMsg(
                    `错误，请尝试重新登录 RunTech -  ${err}`
                )
                console.log(err);
            });
        // setHistoryDicts(historyDicts);
        // console.log(historyDicts)
        setIsLoading(false);
    }


    useEffect(() => {
        fetchStatus();
        // fetchHistory();
    }, [])

    return (
        <Container maxWidth="md">
            <Backdrop open={isLoading} sx={{ zIndex: 99 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={isSnackbarOpen}
                message={errMsg}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setIsSnackbarOpen(false);
                }} />
            <Stack spacing={2}>
                <h3>设置页</h3>
                <Typography variant="h6">服务器状态</Typography>
                <Stack spacing={1}>
                    <Button fullWidth variant="outlined" onClick={fetchStatus}>点击查询</Button>
                    <Alert severity="info">
                        <AlertTitle>服务器状态</AlertTitle>
                        <b>Auth 服务器：</b>{authStatus}
                        <br />
                        <b>API 服务器：</b>{apiStatus}
                        <br />
                        <b>API 延迟：</b>{apiDelay}ms
                    </Alert>
                </Stack>
                <Typography variant="h6">查询签到记录</Typography>
                <Typography variant="body2" color="text.secondary">
                    请注意：查询记录需要登录 RunTech，<b>请不要频繁查询，以免被封禁登录半小时</b>
                    <br />
                    记录来自 Egate 桌面端接口，手机端有延迟属于正常现象
                    <br />
                    有效的判定方法为：<code>入场、离场签到 != null && 活动类型 == "形势与政策课程讲座"</code>，不代表官方标准
                </Typography>
                <Stack spacing={1}>
                    <Button fullWidth variant="outlined" onClick={fetchHistory}>查询</Button>
                    <Typography variant="body2" color="text.secondary">
                        您一共参加了 {historyList.length} 次活动，有效 {historyList.filter((x) => x[0] == "\u2714\uFE0F").length} 次
                    </Typography>
                    <AnyListTable
                        titles={["有效", "入场签到", "离场签到", "活动状态", "活动名称"]}
                        contents={historyList} />
                </Stack>
            </Stack>
        </Container>
    )
}