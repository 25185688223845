import { Container, Grid, Typography, Button } from "@mui/material";
import UserList from "../components/userlistCard";
import UserListv2 from "../components/userlistv2";
import Scanner from "../components/qrScanner";

export const QrPagev2 = () => {
    const sendQR = () => eval(`scanQR("${localStorage.getItem("customHost")}")`);
    return (
        <Container>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 10 }}>
                <Grid item xs={4} sm={4} md={10}>
                    <h3>NOTE: 试试快速提交二维码</h3>
                    <Typography variant="body2" color="text.secondary">
                        <b>仅限今日校园内打开有效</b>
                    </Typography>

                    <Button onClick={sendQR} variant='contained'>试试更好的二维码扫描</Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} style={{ maxWidth: "100%" }}>
                    <Scanner />
                </Grid>
                <Grid item xs={2} sm={4} md={6} style={{ maxWidth: "100%" }}>
                    <UserListv2 maxHeight={500} />
                </Grid>
            </Grid>
        </Container>
    );
}