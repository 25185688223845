import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { scan, ScanResult } from 'qr-scanner-wechat'
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { parseURL, parsev2URL } from "../utils";
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, FormControl, IconButton, IconButtonProps, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, Snackbar, Stack, styled } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

const interval_ms = 1000;

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Scanner = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const webcamRef = useRef<null | Webcam>(null);
    const [currentQRCode, setCurrentQRCode] = useState('');

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [deviceId, setDeviceId] = useState({});
    const [currentDeviceId, setCurrentDeviceId] = useState("");
    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );
    const [SnackbarOpen, setSnackbarOpen] = useState(false);
    const [SnackbarMessage, setSnackbarMessage] = useState('');

    const [use2x, setUse2x] = useState(1);
    const ref2x = useRef(use2x);
    const [exposureCompensation, setExposureCompensation] = useState(0);
    const refExposure = useRef(exposureCompensation);
    const [currentExposureCompensation, setCurrentExposureCompensation] = useState(-100);
    const [brightness, setBrightness] = useState(25);
    const refBrightness = useRef(brightness);
    const [currentBrightness, setCurrentBrightness] = useState(-1);

    useEffect(() => {
        ref2x.current = use2x;
    }, [use2x])

    useEffect(() => {
        refExposure.current = exposureCompensation;
    }, [exposureCompensation])

    useEffect(() => {
        refBrightness.current = brightness;
    }, [brightness])

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }

    useEffect(() => {
        const decode = async () => {
            const screenshot = webcamRef.current?.getCanvas();
            if (!screenshot) {
                await new Promise(resolve => setTimeout(resolve, interval_ms)).then(decode);
                return;
            }
            let result: undefined | ScanResult = undefined;
            try {
                const context = screenshot.getContext('2d');
                if (!context) {
                    alert('Failed to get 2d context');
                    throw "__";
                }
                // console.log(ref2x.current, "x");
                result = await scan(context.getImageData(
                    screenshot.width * (ref2x.current - 1) / 2 / ref2x.current,
                    screenshot.height * (ref2x.current - 1) / 2 / ref2x.current,
                    screenshot.width / ref2x.current,
                    screenshot.height / ref2x.current));

                try {
                    //@ts-ignore
                    var expval = webcamRef.current?.stream?.getVideoTracks().at(0)?.getSettings().exposureCompensation;
                    if (expval !== undefined) {
                        setCurrentExposureCompensation(expval);
                    } else {
                        setCurrentExposureCompensation(-100);
                        throw `Unsupport ExposureCompensation: ${expval}`;
                    }
                    try {
                        if (expval !== undefined && refExposure.current !== expval)
                            webcamRef.current?.stream?.getVideoTracks().at(0)?.applyConstraints({
                                advanced: [
                                    //@ts-ignore
                                    { exposureCompensation: refExposure.current },
                                ]
                            })
                    } catch (e) {
                        setSnackbarOpen(true);
                        console.log("Failed to set exposureCompensation", e)
                    }
                } catch (e) {
                    console.log(e)
                }



                try {
                    //@ts-ignore
                    var brightval = webcamRef.current?.stream?.getVideoTracks().at(0)?.getSettings().brightness;
                    if (brightval !== undefined) {
                        setCurrentBrightness(brightval);
                    } else {
                        setCurrentBrightness(-1);
                        throw `Unsupport Brightness: ${brightval}`;
                    }
                    try {
                        if (brightval !== undefined && refBrightness.current !== brightval)
                            webcamRef.current?.stream?.getVideoTracks().at(0)?.applyConstraints({
                                advanced: [
                                    //@ts-ignore
                                    { brightness: refBrightness.current },
                                ]
                            })
                    } catch (e) {
                        console.log("Failed to set brightness", e)
                    }
                } catch (e) {
                    console.log(e)
                    // setCurrentBrightness(-1);
                }
            } catch (e) {
                console.log("Error:", e);
            }
            if (result?.text) {
                setCurrentQRCode(result?.text);
            }
            await new Promise(resolve => setTimeout(resolve, interval_ms)).then(decode);
        }
        decode();
    }, [webcamRef]);

    useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
            // console.log(navigator.mediaDevices.getSupportedConstraints())
        },
        [handleDevices]
    );

    useEffect(() => {
        if (currentQRCode && currentQRCode !== '') {
            setSnackbarMessage("检测到新的二维码：" + currentQRCode);
            setSnackbarOpen(true);
            fetch(parsev2URL('/setqrcode'),
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        code: `${currentQRCode}`,
                        contributor: localStorage.getItem("nickname"),
                    })
                })
                .catch(err => {
                    console.log(err);
                });
            fetch(('https://runapi.zrlnmsl.top:8088/api/v1/setqrcode'),
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        code: `${currentQRCode}`,
                        contributor: localStorage.getItem("nickname"),
                    })
                })
                .catch(err => {
                    console.log(err);
                });
            fetch(`https://r.zrlnmsl.top/qrpost`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    code: `${currentQRCode}`,
                    contributor: localStorage.getItem("nickname"),
                })
            })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [currentQRCode]);

    useEffect(() => {
        if (devices.length > 1) {
            setCurrentDeviceId(devices[1].deviceId);
        }
    }, [devices]);

    const onUserMedia = () => {
        const qrinfo = document.getElementById('qrinfo');
        setCurrentQRCode('')
    }

    const onUserMediaError = () => {
        console.log('Error accessing user media');
        const qrinfo = document.getElementById('qrinfo');
        setSnackbarMessage("访问摄像头失败，请检查权限设置，并确保你在使用https协议/localhost访问本页面") // Temp
        setSnackbarOpen(true);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentDeviceId(event.target.value as string);
    };

    return (
        <>
            <Snackbar open={SnackbarOpen} autoHideDuration={500} onClose={handleSnackbarClose} message={SnackbarMessage} />
            <Card>
                <CardHeader
                    title="QR Code Scanner"
                />
                <CardContent>
                    <Stack spacing={2}>
                        <Paper style={{ overflow: 'hidden' }}>
                            <Webcam
                                ref={webcamRef}
                                audio={false}
                                onUserMedia={onUserMedia}
                                onUserMediaError={onUserMediaError}
                                forceScreenshotSourceSize={true}
                                videoConstraints={{
                                    deviceId: currentDeviceId,
                                    facingMode: { ideal: "environment" },
                                    height: { ideal: 1920, max: 3840 },
                                    width: { ideal: 1080, max: 2160 },

                                }}
                                style={{ maxWidth: '100%', marginBottom: '-8px', scale: `${use2x}` }}
                            />
                        </Paper>
                        <Typography id="qrinfo" variant="body2" color="textSecondary" style={{ maxWidth: "100%", wordBreak: "normal" }}>
                            {`${webcamRef.current?.video?.videoWidth}x${webcamRef.current?.video?.videoHeight}`}
                            <br />
                            您的浏览器
                            {JSON.stringify(navigator.mediaDevices.getSupportedConstraints()).includes("exposureCompensation") ? "支持" : "不支持"}
                            曝光补偿，
                            {JSON.stringify(navigator.mediaDevices.getSupportedConstraints()).includes("brightness") ? "支持" : "不支持"}
                            亮度调节
                            <br />
                            当前曝光：
                            {currentExposureCompensation === -100 ? "不支持" : `${currentExposureCompensation.toFixed(2)}`}
                            ，
                            当前亮度：
                            {currentBrightness === -1 ? "不支持" : `${currentBrightness}`}

                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions disableSpacing>
                    {/* <Button variant={use2x? 'contained':'outlined'} onClick={() => {setUse2x(!use2x)}}>放大</Button> */}
                    <Typography variant="body2" color="text.secondary" marginLeft={1}>
                        点击 设置 按钮可以切换和配置摄像头
                    </Typography>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <SettingsIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Stack padding={2}>
                        <Stack padding={2}>
                            <Typography variant="body2" color="text.secondary" marginLeft={1}>
                                {use2x} 倍放大模式
                            </Typography>
                            <Slider
                                defaultValue={1}
                                min={1}
                                max={10}
                                onChange={(event, value) => { if (typeof value === 'number') setUse2x(value) }}
                            />
                            <Typography variant="body2" color="text.secondary" marginLeft={1}>
                                亮度：{brightness}/{currentBrightness === -1 ? "不支持" : (currentBrightness)}
                            </Typography>
                            <Slider
                                defaultValue={25}
                                min={1}
                                max={100}
                                onChange={(event, value) => { if (typeof value === 'number') setBrightness(value) }}
                                disabled={currentBrightness === -1}
                            />
                            <Typography variant="body2" color="text.secondary" marginLeft={1}>
                                曝光：{exposureCompensation}/{currentExposureCompensation === -100 ? "不支持" : (currentExposureCompensation)}
                            </Typography>
                            <Slider
                                defaultValue={0}
                                min={-4}
                                max={4}
                                step={0.1}
                                onChange={(event, value) => { if (typeof value === 'number') setExposureCompensation(value) }}
                                disabled={currentExposureCompensation === -100}
                            />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel id="device-select-label">Select Camera</InputLabel>
                            <Select
                                labelId="device-select-label"
                                id="device-select"
                                value={currentDeviceId}
                                label="Select Camera"
                                onChange={handleChange}
                            >
                                {devices.map((device, key) => (
                                    <MenuItem value={device.deviceId}>{device.label || `Device ${key + 1}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Collapse>
            </Card>
        </>
    );
}

export default Scanner;
