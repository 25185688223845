import ReactDOM from 'react-dom';
import MainNavigation from './NewApp';
import { Analytics } from "@vercel/analytics/react"
// import App from './App'


const root = document.getElementById('root');

try {
    const hash = window.location.hash.substring(1); // remove the #
    if (hash) {
        localStorage.setItem('customHost', hash);
    }
} catch { }

ReactDOM.render(
    <>
        <Analytics />
        <MainNavigation />
    </>, root);
