import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Container, Typography, CssBaseline, TextField, Link, Grid, Box, Alert, CircularProgress, Icon, Paper, Snackbar, useMediaQuery, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { parseURL, parsev2URL } from '../utils';
import { useTheme } from '@emotion/react';
import { useQrious } from 'react-qrious'
import { randomInt } from 'crypto';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            登录上科大账号后，<b>你的 Cookies 将被存入服务器的缓存中</b>，每次有新的二维码递交时，将会为您自动尝试扫码
            <br />
            <br />
            <b>请勿将密码或二维码分享给任何不可信的 RunTech 服务器</b>
        </Typography>
    );
}

export default function LoginBoxv2() {
    const classes = useTheme();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [errmsg, setErrmsg] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const [qrOpen, setQrOpen] = React.useState(false);
    const [qrLoading, setqrLoading] = React.useState(false);
    const [value, setValue] = React.useState(''); // Qr value
    const [dataUrl, _qrious] = useQrious({ value, size: 150 });

    useEffect(() => { setOpen(errmsg !== "") }, [errmsg])

    const submit = () => {
        const usernameInput = document.getElementById("username") as HTMLInputElement;
        const passwordInput = document.getElementById("password") as HTMLInputElement;
        const nicknameInput = document.getElementById("nickname") as HTMLInputElement;
        console.log("submit");
        if (!(usernameInput.value && passwordInput.value)) {
            setErrmsg("请填写用户名与密码")
            return
        }
        localStorage.setItem('nickname', nicknameInput.value);

        setLoading(true);
        
        setTimeout(() => { setLoading(false) }, 10000);

        fetch(parsev2URL("/login"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: usernameInput.value,
                password: passwordInput.value,
                nickname: nicknameInput.value,
                type: "generalLogin",
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched")
                if (data?.status == "200") {
                    localStorage.setItem('uid', `${data.uid}`);
                    localStorage.setItem('id', usernameInput.value);
                    console.log("Logged")
                    setSuccess(true);
                }
                else {
                    console.log("Failed")
                    setErrmsg(data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrmsg(error.toString());
            })
            .finally(() => { setLoading(false); })
    }

    const logout = () => {
        const usernameInput = document.getElementById("username") as HTMLInputElement;
        if (!usernameInput.value) {
            setErrmsg("请填写用户名")
            return
        }

        setLoading(true);

        setTimeout(() => { setLoading(false) }, 10000);

        fetch(parsev2URL("/logout"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: usernameInput.value,
                uid: localStorage.getItem("uid"),
            })
        })
            .then(response => response.json())
            .then(data => {
                setErrmsg(data.message)
            })
            .catch((error) => {
                setErrmsg(error.toString())
            })
            .finally(() => {
                localStorage.removeItem('uid')
                setLoading(false)
                setSuccess(false)
            })
    }

    const handleQrClickOpen = () => {
        setQrOpen(true);
        setqrLoading(true);

        fetch(parseURL('/api/v1/qrinit'))
            .then(response => response.json())
            .then(data => {
                if (data.status == 200) {
                    localStorage.setItem("uuid", data.data.uuid);
                    localStorage.setItem("execution", data.data.execution);
                    localStorage.setItem("cookies", data.data.cookies);
                    setValue(data.data.qrcode);
                }
                else throw new Error("Server Returned Failed");
            })
            .catch(err => {
                console.log(err);
                setErrmsg(`${err}`);
                setOpen(true);
            })
            .finally(() => {
                setqrLoading(false)
            })
    };

    const handleQrClose = () => {
        setQrOpen(false);
    };

    const handleQrFinish = () => {
        setQrOpen(false);
        setLoading(true);

        setTimeout(() => {
            setLoading(false)
        }, 10000);

        fetch(parseURL('/api/v1/qrlogin'), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                uuid: localStorage.getItem('uuid'),
                execution: localStorage.getItem('execution'),
                cookies: localStorage.getItem('cookies')
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched")
                if (data?.status == "200") {
                    localStorage.setItem('cookies', `${data.data}`);
                    console.log("Logged")
                    setSuccess(true);
                }
                else {
                    console.log("Failed")
                    setErrmsg(data.message);
                    setOpen(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrmsg(error.toString());
                setOpen(true);
            })
            .finally(() => { setLoading(false); })
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={errmsg}
            />
            <Backdrop
                open={loading}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={qrOpen}
                onClose={handleQrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"扫码登录"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack spacing={2}>
                            <Typography>
                                请打开 今日校园 APP, 使用 <b>主页</b> 右上角的扫码按钮进行扫码登录 <br />
                                或长按保存图片后在扫码界面从相册中选择
                            </Typography>
                            <Grid alignSelf='center'>
                                {qrLoading ? <CircularProgress /> : (<img src={dataUrl} />)}
                            </Grid>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQrClose}>取消登录</Button>
                    <Button onClick={handleQrFinish} variant="contained" autoFocus>
                        我已完成登录
                    </Button>
                </DialogActions>
            </Dialog>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Paper style={{
                    padding: '40px'
                }}>
                    <Avatar>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        使用你的 统一身份认证 登录
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Student ID"
                        name="username"
                        autoComplete="username"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        // color={(localStorage.getItem('customHost') == null || localStorage.getItem('customHost') == "") ? "primary" : "warning"}
                        // helperText={(localStorage.getItem('customHost') == null || localStorage.getItem('customHost') == "") ? "" : "您已连接到非原生 API 服务器，请注意数据安全，建议使用二维码登录"}
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nickname"
                        label="Nickname"
                        name="nickname"
                        helperText="Nickname 用于显示在用户列表中"
                        
                        defaultValue={localStorage.getItem('nickname') || "User " + Math.random().toString(36).substring(3, 7).toUpperCase()}
                    />
                    <Stack spacing={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={success || loading}
                            onClick={submit}
                        >
                            {success ? "登录成功" : localStorage.getItem("uid") == null? "登录": "重新登录"}
                        </Button>
                        <Button
                            disabled={localStorage.getItem('uid') == null}
                            variant="outlined"
                            color="error"
                            onClick={logout}
                        >
                            登出
                        </Button>
                        <Button fullWidth onClick={handleQrClickOpen} disabled={true}>
                            {"使用 今日校园APP 扫码登录 （重构中）"}
                        </Button>
                    </Stack>
                </Paper>
                <Box mt={3}>
                    <Copyright />
                </Box>
            </Container>
        </>
    );
}
