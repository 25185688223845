import { useEffect, useState } from "react"

export function parseURL(url?: string) {
    if (localStorage.getItem('customHost') === null)
        localStorage.setItem('customHost', '')
    return `${localStorage.getItem('customHost')}${url}`
}

export function parsev2URL(url?: string) {
    // if (localStorage.getItem('customHost') === null)
    //     localStorage.setItem('customHost', '')
    // return `${localStorage.getItem('customHost')}${url}`
    const env = process.env.NODE_ENV
    if (env === 'production') {
        return "https://runapi.zrlnmsl.top:5000/api/v2" + url
    }
    else {
        return "https://runapi.zrlnmsl.top:5000/api/v2" + url
        return "http://localhost:8080/api/v2" + url
    }
}

export function getCookie() {
    return localStorage.getItem('cookies') || ''
}

export function isSessionValid() {
    return getCookie() != "" && localStorage.getItem('name') != "" && localStorage.getItem('id') != ""
}

export function fetchWithCookie(url: string) {
    return fetch(parseURL(url), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: getCookie(),
    })
}

export function timestamp2Duration(timestamp: number) {
    const duration = Date.now() - timestamp
    if (duration < 1000) {
        return `${duration}ms`
    }
    else if (duration < 60000) {
        return `${Math.floor(duration / 1000)}s`
    }
    else if (duration < 3600000) {
        return `${Math.floor(duration / 60000)}m`
    }
    else {
        return `${Math.floor(duration / 3600000)}h`
    }
}


export function timestamp2Past(timestamp: number) {
    const duration = Date.now() - timestamp
    if (duration < 1000) {
        return `刚刚`
    }
    else if (duration < 60 * 1000) {
        return `${Math.floor(duration / 1000)} 秒前`
    }
    else if (duration < 60 * 60 * 1000) {
        return `${Math.floor(duration / 60000)} 分钟前`
    }
    else if (duration < 3 * 60 * 60 * 1000) {
        return `${(duration / 3600000).toFixed(1)} 小时前`
    } else {
        return `很久以前`
    }
}


export function timestamp2PastShort(timestamp: number) {
    const duration = Date.now() - timestamp
    if (duration < 1000) {
        return `Recent`
    }
    else if (duration < 60 * 1000) {
        return `${Math.floor(duration / 1000)} s ago`
    }
    else if (duration < 60 * 60 * 1000) {
        return `${Math.floor(duration / 60000)} m ago`
    }
    else if (duration < 3 * 60 * 60 * 1000) {
        return `${(duration / 3600000).toFixed(1)} h ago`
    } else {
        return `Long ago`
    }
}