import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useQrious } from 'react-qrious'
import Grid from '@mui/material/Grid';
import { Container, LinearProgress, Paper, Slider, Stack } from '@mui/material';
import { parseURL } from '../utils';

export default function QRCard() {
    const [value, setValue] = React.useState('');
    const [dataUrl, _qrious] = useQrious({ value, size: 150 });
    const [updateTimeStamp, setUpdateTimeStamp] = React.useState(-1590354886);
    const [fetchTimeStamp, setFetchTimeStamp] = React.useState(-1590354886);
    const [contributor, setContributor] = React.useState('点击开始获取二维码');
    const [updating, setUpdating] = React.useState(false);
    const [loop, setloop] = React.useState(2);
    const theme = useTheme();

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (updating)
                fetch(('https://runapi.zrlnmsl.top:5000/api/v2/getqrcode'))
                    .then(response => response.json())
                    .then(data => {
                        setValue(data.code);
                        setContributor(data.contributor);
                        setUpdateTimeStamp(data.update);
                        setFetchTimeStamp(Date.now());
                    })
        }, 1000 * loop);
        return () => {
            clearInterval(interval);
        };
    }, [updating, loop]);

    const toggleUpdate = () => {
        setUpdating(!updating);
    }

    const handleLoopChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setloop(newValue);
        }
    };

    return (
        <Card sx={{ display: 'flex' }}>
            <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={0} margin={3} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={3} sm={4} md={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                Contributor:
                            </Typography>
                            <Typography component="div" variant="h5">
                                {contributor}
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                Updated at: {new Date(updateTimeStamp * 1000).toLocaleString()}
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                Fetched at: {new Date(fetchTimeStamp).toLocaleString()}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, pr: 4 }}>
                            <IconButton aria-label="start/pause" onClick={toggleUpdate}>
                                {updating ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={1} sm={1} md={1}>
                    <Stack spacing={2} direction="column" sx={{ mb: 1 }} alignItems="center">
                        {'🐢'}
                        <Slider
                            disabled={false}
                            marks
                            max={3}
                            min={1}
                            size="small"
                            valueLabelDisplay="auto"
                            orientation="vertical"
                            defaultValue={2}
                            onChange={handleLoopChange}
                            style={{ height: "100px", margin: "10px" }}
                        />
                        {'🐇'}
                    </Stack>
                </Grid>
                <Grid xs={'auto'} sm={3} md={3}>
                    <Paper sx={{ display: 'inline-flex', flexDirection: 'column', padding: '10px' }}>
                        <img src={dataUrl} />
                    </Paper>
                </Grid>
                <Grid xs={13}>
                    <Box sx={{ width: '100%', marginTop: '10px' }}>
                        <LinearProgress 
                            variant={updating ? "indeterminate" : "determinate"} 
                            value={0} />
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
