import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge, Button, ButtonGroup, Chip, Collapse, Skeleton, Stack, TablePagination, Typography, useTheme } from '@mui/material';
import { parseURL, parsev2URL, timestamp2Past, timestamp2PastShort } from '../utils';
import { time } from 'console';
import { AccessTimeFilled, CheckCircle, PublishedWithChanges, Error, Pending, Info, CheckCircleTwoTone, PendingTwoTone, ErrorTwoTone, PublishedWithChangesTwoTone, InfoOutlined, AccessTimeFilledTwoTone, PublishedWithChangesRounded, VerifiedTwoTone, Verified, VerifiedOutlined, PendingOutlined } from '@mui/icons-material';
import { Suspense, useDeferredValue } from 'react';

function createData(
  name: string,
  id: string,
  lastLogin: number,
  lastUpdate: number,
  lastSuccess: number,
  status: string,
) {
  return { name, id, lastLogin, lastUpdate, lastSuccess, status };
}

export interface UserListProps {
  maxHeight?: number;
}

export default function UserListv2Cont({ maxHeight }: UserListProps) {
  const theme = useTheme();
  const [rows, setRows] = React.useState([
    createData('少女祈祷中', "1234567890", 0, 0, 0, "登录失效"),
  ]);
  const deferredRows = useDeferredValue(rows);
  const [lastUPdate, setLastUpdate] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateUserList = () => {
    setIsFetching(true);
    fetch(parsev2URL('/users'))
      .then(response => response.json())
      .then(data => {
        // var rows = [];
        const newRows = data.reduce((acc: any[], user: any) => {
          acc.push(createData(
            user.nickname, user.uid, user.last_login_time, user.last_scan_time, user.last_success_time, user.status
          ));
          return acc;
        }, []);
        setRows(newRows);
        return data;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLastUpdate(Date.now());
        setIsFetching(false);
      });
  }

  React.useEffect(updateUserList, []);

  return (
    <Stack spacing={2}>
      <ButtonGroup fullWidth>
        <Button variant='contained' disabled={isFetching} onClick={updateUserList}>刷新列表</Button>
        <Button variant={expanded ? 'contained' : 'outlined'} onClick={() => setExpanded(!expanded)}>{expanded ? "收起" : "展开"}详情</Button>
      </ButtonGroup>
      <Suspense fallback={<Skeleton variant="rectangular" height={maxHeight} />}>
        <Paper>
          <TableContainer sx={maxHeight ? { maxHeight: maxHeight } : {}}>
            <Table stickyHeader aria-label="user list" sx={{ minWidth: '400px' }} size="small">
              <caption>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <span><b>{deferredRows.length}</b> 个用户在线</span>
                    <br />
                    <span>最后更新时间：{new Date(lastUPdate).toLocaleString()}</span>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={deferredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </caption>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: "1em" }}></TableCell>
                  <TableCell>用户名</TableCell>
                  <TableCell sx={{ minWidth: "12em" }}>状态</TableCell>
                  <TableCell align="right" sx={{ minWidth: "8em" }}>最近成功</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deferredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <>
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <Chip label={timestamp2Past(Math.max(row.lastLogin, row.lastUpdate) * 1000)} icon={
                            {
                              "已登录": <PendingOutlined />,
                              "二维码已失效": <AccessTimeFilledTwoTone />,
                              "签到成功": <CheckCircle />,
                              "已重新登录": <PublishedWithChanges />,
                              "登录失效": <Error />,
                            }[row.status]
                          }
                            //@ts-ignore
                            color={
                              {
                                "已登录": 'default',
                                "二维码已失效": 'warning',
                                "签到成功": 'success',
                                "已重新登录": 'info',
                                "登录失效": 'error',
                              }[row.status] || 'default'
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">
                          {isFetching ? <Typography><Skeleton /></Typography> : <span style={{ fontSize: '1em', fontWeight: (row.status == "签到成功" ? 'bolder' : 'inherit') }}>{row.status}</span>}
                        </TableCell>
                        <TableCell align="right">{row.lastSuccess > 0 ? timestamp2Past(row.lastSuccess * 1000) : "无"}</TableCell>
                      </TableRow>
                      {expanded && (
                        <TableRow style={{ backgroundColor: theme.palette.action.hover }}>
                          <TableCell colSpan={5} align="left">
                            ID:
                            {row.id.substring(Math.max(row.id.length - 8, 0))}
                            <br />
                            最近登录时间:
                            {new Date(row.lastLogin * 1000).toLocaleString()}
                            <br />
                            最近扫码时间:
                            {row.lastUpdate > 0 ? new Date(row.lastUpdate * 1000).toLocaleString() : "无"}
                            <br />
                            最近成功时间:
                            {row.lastSuccess > 0 ? new Date(row.lastSuccess * 1000).toLocaleString() : "无"}
                          </TableCell>
                        </TableRow>)}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
      </Suspense>
    </Stack>
  );
}

export const UserListV2 = React.memo(UserListv2Cont);